export const sponsorCompanyList = {
  sg: [
    { title: 'KPMG', image_url: '/img/updated/homepage/sponsor_images/kpmg.png' },
    { title: 'CNA', image_url: '/img/updated/homepage/sponsor_images/cna.png' },
    { title: 'ELLE', image_url: '/img/updated/homepage/sponsor_images/elle.png' },
    { title: 'Harper\'s Bazaar', image_url: '/img/updated/homepage/sponsor_images/bazaar.png' },
    { title: 'Mens\'s Folio', image_url: '/img/updated/homepage/sponsor_images/mens_folio.png' },
    { title: 'The Straits Times', image_url: '/img/updated/homepage/sponsor_images/the_straits_times.png' }
  ],
  my: [
    { title: 'KPMG', image_url: '/img/updated/homepage/sponsor_images/kpmg.png' },
    { title: 'Beauty Insider', image_url: '/img/updated/homepage/sponsor_images/beauty_insider.png' },
    { title: 'Harper\'s Bazaar', image_url: '/img/updated/homepage/sponsor_images/bazaar.png' },
    { title: 'Eh! Malaysia', image_url: '/img/updated/homepage/sponsor_images/eh_malaysia.png' },
    { title: 'CittaBella', image_url: '/img/updated/homepage/sponsor_images/cittabella.png' }
  ],
  hk: [
    { title: 'KPMG', image_url: '/img/updated/homepage/sponsor_images/kpmg.png' },
    { title: 'ELLE', image_url: '/img/updated/homepage/sponsor_images/elle.png' },
    { title: 'Cosmopolitan', image_url: '/img/updated/homepage/sponsor_images/cosmopolitan.png' },
    { title: 'Harper\'s Bazaar', image_url: '/img/updated/homepage/sponsor_images/bazaar.png' },
    { title: 'HK01', image_url: '/img/updated/homepage/sponsor_images/hk01.png' },
    { title: 'She.com', image_url: '/img/updated/homepage/sponsor_images/she_com.png' }
  ],
  tw: [
    { title: 'KPMG', image_url: '/img/updated/homepage/sponsor_images/kpmg.png' },
    // { title: 'Jusky', image_url: '/img/updated/homepage/sponsor_images/jusky.png' },
    // { title: 'Pop Daily ', image_url: '/img/updated/homepage/sponsor_images/nownews.png' },
    { title: 'Liberty Times Net ', image_url: '/img/updated/homepage/sponsor_images/liberty_times_net.png' },
    { title: 'China Times', image_url: '/img/updated/homepage/sponsor_images/chinatimes.png' },
    { title: 'Economic Daily', image_url: '/img/updated/homepage/sponsor_images/economic_daily.png' },
    { title: 'Now News ', image_url: '/img/updated/homepage/sponsor_images/nownews.png' }
  ],
  vn: [

    { title: 'KPMG', image_url: '/img/updated/homepage/sponsor_images/kpmg.png' },
    { title: 'ELLE', image_url: '/img/updated/homepage/sponsor_images/elle.png' },
    { title: 'Cosmopolitan', image_url: '/img/updated/homepage/sponsor_images/cosmopolitan.png' },
    { title: 'Cafebiz', image_url: '/img/updated/homepage/sponsor_images/cafebiz.png' },
    { title: 'Zingnews', image_url: '/img/updated/homepage/sponsor_images/zing_news.png' },
    { title: 'Kenh14.vn', image_url: '/img/updated/homepage/sponsor_images/kenh_14.png' }

  ],
  th: [

    { title: 'KPMG', image_url: '/img/updated/homepage/sponsor_images/kpmg.png' },
    { title: 'Sanook', image_url: '/img/updated/homepage/sponsor_images/sanook.png' },
    { title: 'Jeban', image_url: '/img/updated/homepage/sponsor_images/jeban.png' },
    { title: 'Wongnai', image_url: '/img/updated/homepage/sponsor_images/wongnai.png' },
    { title: 'Pantip', image_url: '/img/updated/homepage/sponsor_images/pantip.png' },
    // { title: 'Ad Addict', image_url: '/img/updated/homepage/sponsor_images/ad_addict.png' },
    { title: 'Longtoon man', image_url: '/img/updated/homepage/sponsor_images/longtoonman.png' }

  ],
  jp: [
    { title: 'KPMG', image_url: '/img/updated/homepage/sponsor_images/kpmg.png' },
    { title: 'Nikkei', image_url: '/img/updated/homepage/sponsor_images/nikkei.png' },
    { title: 'The Sankei News', image_url: '/img/updated/homepage/sponsor_images/sankei.png' },
    { title: 'Yahoo', image_url: '/img/updated/homepage/sponsor_images/yahoo_japan.png' },
    { title: 'WWD', image_url: '/img/updated/homepage/sponsor_images/wwd.png' },
    { title: 'BS11', image_url: '/img/updated/homepage/sponsor_images/bs11.png' },
    { title: 'Kobe Newspaper', image_url: '/img/updated/homepage/sponsor_images/kobe_newspaper.png' }
  ],
  id: [
    { title: 'KPMG', image_url: '/img/updated/homepage/sponsor_images/kpmg.png' },
    { title: 'CNA', image_url: '/img/updated/homepage/sponsor_images/cna.png' },
    { title: 'ELLE', image_url: '/img/updated/homepage/sponsor_images/elle.png' },
    { title: 'Beauty Insider', image_url: '/img/updated/homepage/sponsor_images/beauty_insider.png' }
  ]
};

export const sponsorSlider = {
  arrows: false,
  accessibility: true,
  infinite: false,
  speed: 700,
  slidesToScroll: 1,
  dots: false,
  swipe: false,
  slidesToShow: 7,
  touchThreshold: 10,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        swipe: true,
        slidesToShow: 6
      }
    },
    {
      breakpoint: 986,
      settings: {
        swipe: true,
        slidesToShow: 4
      }
    },
    {
      breakpoint: 768,
      settings: {
        swipe: true,
        slidesToShow: 3
      }
    },
    {
      breakpoint: 576,
      settings: {
        swipe: true,
        slidesToShow: 3
      }
    },
    {
      breakpoint: 320,
      settings: {
        swipe: true,
        slidesToShow: 2
      }
    }
  ]
};

export const cardSlider = {
  arrows: false,
  accessibility: true,
  infinite: false,
  speed: 700,
  slidesToScroll: 1,
  dots: false,
  swipe: false,
  slidesToShow: 5,
  touchThreshold: 10,
  beforeChange: (current, next) => {
    const activeNumber = Math.ceil(next) + 1;
    const slideLength = document.querySelectorAll('.steps-card-slider .slick-dots li').length;
    if (activeNumber === slideLength) {
      document.querySelector('.steps-card-slider .slick-dots li:last-of-type').classList.add('slick-active');
    } else {
      document.querySelector('.steps-card-slider .slick-dots li:last-of-type').classList.remove('slick-active');
    }
  },
  responsive: [
    {
      breakpoint: 1368,
      settings: {
        swipe: true,
        slidesToShow: 4.3,
        dots: true
      }
    },
    {
      breakpoint: 1136,
      settings: {
        swipe: true,
        slidesToShow: 3.3,
        dots: true
      }
    },
    {
      breakpoint: 1025,
      settings: {
        swipe: true,
        slidesToShow: 3.3,
        dots: true
      }
    },
    {
      breakpoint: 933,
      settings: {
        swipe: true,
        slidesToShow: 2.3,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        swipe: true,
        slidesToShow: 2.3,
        dots: true
      }
    },
    {
      breakpoint: 576,
      settings: {
        swipe: true,
        slidesToShow: 1.3,
        dots: true
      }
    }
  ]
};

export const sliderImgs = [
  {
    title: 'Visa',
    url: '/img/updated/homepage/payyourway/Visa.png'
  },
  {
    title: 'MasterCard',
    url: '/img/updated/homepage/payyourway/Mastercard.png'
  },
  {
    title: 'Atome',
    url: '/img/updated/homepage/payyourway/Atome_full_yellow.png'
  },
  {
    title: 'Shopback',
    url: '/img/updated/homepage/payyourway/Shopback.png'
  },
  {
    title: 'Amex',
    url: '/img/updated/homepage/payyourway/Amex.png'
  },
  {
    title: 'JCB',
    url: '/img/updated/homepage/payyourway/JCB.png'
  },
  {
    title: 'PromptPay',
    url: '/img/updated/homepage/payyourway/Promptpay.png'
  },
  {
    title: 'Smartpay',
    url: '/img/updated/homepage/payyourway/Smartpay.png'
  },
  {
    title: '2C2P',
    url: '/img/updated/homepage/payyourway/2C2P.png'
  },
  {
    title: 'Chailease Holding',
    url: '/img/updated/homepage/payyourway/Chailease.png'
  },
  {
    title: 'Easygop',
    url: '/img/updated/homepage/payyourway/Easygop.png'
  }
];

export const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  arrows: false,
  slidesToShow: sliderImgs.length,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    }
  ]
};

export const homefavSlider = {
  accessibility: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  dots: false,
  touchThreshold: 10,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        swipe: true,
        slidesToShow: 2.7
      }
    },
    {
      breakpoint: 768,
      settings: {
        swipe: true,
        slidesToShow: 2.4
      }
    },
    {
      breakpoint: 576,
      settings: {
        swipe: true,
        slidesToShow: 1.91
      }
    }
  ]
};

// export const beforeAfterImages = [
//   { name: 'Nicole', mobile: true },
//   { name: 'Benjamin', mobile: true },
//   { name: 'HelenTan', mobile: true },
//   { name: 'DarrenAng', mobile: false },
//   { name: 'Sherri', mobile: false },
//   { name: 'KevinOng', mobile: false }

// ];

export const beforeAfterImages = [
  { name: 'Benjamin', mobile: true },
  { name: 'Benjamin-teeth', mobile: true },
  { name: 'Sherri', mobile: true },
  { name: 'Sherri-teeth', mobile: false },
  { name: 'DarrenAng', mobile: false },
  { name: 'DarrenAng-teeth', mobile: false }
];

export const beforeAfterImagesVn = [
  { name: 'vn/Vn1', mobile: true },
  { name: 'vn/Vn2', mobile: true },
  { name: 'vn/Vn3', mobile: true },
  { name: 'vn/Vn4', mobile: false },
  { name: 'vn/Vn5', mobile: false },
  { name: 'vn/Vn6', mobile: false }
];

export const paymentMethodMap = {
  sg: ['Visa', 'Mastercard', 'Atome', 'Shopback', 'hsbc', 'dbs', 'posb'],
  my: ['Visa', 'Mastercard', 'Atome', 'Shopback', 'ChaileaseCredit'],
  hk: ['Visa', 'Mastercard', 'Atome', 'Shopback'],
  tw: ['Visa', 'Mastercard', 'JCB', 'Zingala'],
  vn: ['Visa', 'Mastercard', 'JCB', 'Amex', 'Easygop'],
  th: ['Visa', 'Mastercard', 'Promptpay', 'Shopback', '2C2P'],
  jp: ['Visa', 'Mastercard', 'Smartpay'],
  id: ['Visa', 'Mastercard']
};

export const multilangcountries = ['jp', 'hk'];

export const clinicLocations = {
  sg: [
    { location: 'Central',
      areas: [
        'Botanic Garden',
        'Novena',
        'Orchard',
        'Raffles Place',
        'Rochor',
        'Suntec City',
        'Tai Seng',
        'Telok Ayer'
      ] },
    { location: 'East',
      areas: [
        'East Coast',
        'Marine Parade',
        'Tanjong Katong',
        'Tampines'
      ] },
    { location: 'North',
      areas: [
        'Ang Mo Kio',
        'Bishan',
        'Sembwang',
        'Woodlands',
        'Yishun',
        'Yio Chu Kang'
      ] },
    { location: 'Northeast',
      areas: [
        'Kovan',
        'Punggol',
        'Serangoon'
      ] },
    { location: 'South',
      areas: [
        'Bukit Merah',
        'Outram Park'
      ] },
    { location: 'West',
      areas: [
        'Boon Lay',
        'Bukit Batok',
        'Buona Vista',
        'Clementi',
        'Holland Village',
        'Jurong West'
      ] }
  ],
  my: [
    { location: 'Kuala Lumpur',
      areas: [
        'Desa Park City',
        'Chow Kit',
        'Taman Danau Desa',
        'Sri Petaling',
        'Taman Melawati',
        'Raja Chulan',
        'Masjid Jamek'
      ] },
    { location: 'Selangor',
      areas: [
        'Cheras Trades Square',
        'Damansara Jaya',
        'Kajang',
        'Klang (Bukit Tinggi)',
        'Kota Kemuning',
        'Petaling Jaya',
        'Rawang',
        'Sepang (Kota Warisan)',
        'Subang Jaya',
        'Jade Hill',
        'Banting'
      ] },
    { location: 'Penang',
      areas: [
        'Bayan Lepas',
        'George Town'
      ] },
    { location: 'Sabah',
      areas: [
        'Kota Kinabalu'
      ] },
    { location: 'Perak',
      areas: [
        'Parit Buntar'
      ] },
    { location: 'Kelantan',
      areas: [
        'Kota Bharu'
      ] },
    { location: 'Terengganu',
      areas: [
        'Kuala Terengganu'
      ] }
  ],
  vn: [
    { location: 'Hà Nội',
      areas: [
        'Bắc Từ Liêm',
        'Cầu Giấy',
        'Đống Đa',
        'Gia Lâm',
        'Hà Đông',
        'Hai Bà Trưng',
        'Hoàn Kiếm',
        'Hoàng Mai',
        'Long Biên',
        'Tây Hồ',
        'Thanh Xuân'
      ] },
    { location: 'Hồ Chí Minh',
      areas: [
        'Bình Tân',
        'Bình Thạnh',
        'Gò Vấp',
        'Quận 1',
        'Quận 3',
        'Quận 5',
        'Quận 9',
        'Quận 10',
        'Quận 12',
        'Tân Bình',
        'Tân Phú',
        'Thủ Đức - Quận 9'
      ] },
    { location: 'Huế',
      areas: [
        'Phú Nhuận'
      ] },
    { location: 'Quảng Tri',
      areas: [
        'Đông Hà'
      ] },
    { location: 'Hải Dương',
      areas: [
        'Lê Thanh Nghị'
      ] },
    { location: 'Đà Nẵng',
      areas: [
        'Cẩm Lệ'
      ] },
    { location: 'Thanh Hoá',
      areas: [
        'Điện Biên'
      ] },
    { location: 'Cà Mau',
      areas: [
        'Cà Mau'
      ] },
    { location: 'Hải Phòng',
      areas: [
        'Hồng Bàng'
      ] },
    { location: 'Đà Lạt',
      areas: [
        'Đà Lạt'
      ] },
    { location: 'Vũng Tàu',
      areas: [
        'Vũng Tàu'
      ] },
    { location: 'Nghệ An',
      areas: [
        'Vinh'
      ] },
    { location: 'Bình Dương',
      areas: [
        'Lái Thiêu',
        'Thủ Dầu Một'
      ] },
    { location: 'Đồng Nai',
      areas: [
        'Biên Hoà'
      ] },
    { location: 'Quảng Ninh',
      areas: [
        'Hạ Long'
      ] },
    { location: 'Thái Nguyên',
      areas: [
        'Hoàng Văn Thụ'
      ] },
    { location: 'Giai Lai',
      areas: [
        'Pleiku'
      ] },
    { location: 'Nha Trang',
      areas: [
        'Phước Hoà'
      ] }
  ],
  // th: [
  //   { location: 'Bangkok',
  //     areas: [
  //       'Asoke',
  //       'Bang Mod',
  //       'Bang Phlat',
  //       'Bangkhae',
  //       'Chaengwattana',
  //       'Charansanitwong',
  //       'Chidlom',
  //       'Don Muang',
  //       'Happy land',
  //       'Hua Lamphong',
  //       'Hua Mak',
  //       'Kanlapaphruk',
  //       'Krung Thep Kritha',
  //       'Ladpraw',
  //       'Lasalle',
  //       'Lat Krabang',
  //       'Lat Pla Khao',
  //       'Minburi',
  //       'Nana',
  //       'Nang Chok',
  //       'Ngamwongwan',
  //       'Nong Khaem',
  //       'On Nut',
  //       'Panya Indra',
  //       'Patthanakarn',
  //       'Phaholyothin',
  //       'Phoem Sin',
  //       'Phra Khanong',
  //       'Pin Klao',
  //       'Pracha Chuen',
  //       'Pracha Uthit',
  //       'Punnawithi',
  //       'Ram Inthra',
  //       'Rama 2',
  //       'Rama 3',
  //       'Rama 7',
  //       'Ramkhamhaeng',
  //       'Ratchada',
  //       'Ratchadamnoen',
  //       'Rom Klao',
  //       'Saphan Sung',
  //       'Sathu Pradit',
  //       'Siam',
  //       'Silom',
  //       'Soi Mai',
  //       'Suan Dusit',
  //       'Suang Luang',
  //       'Suksawat',
  //       'Tha pra',
  //       'Thewet',
  //       'Thonburi',
  //       'Thong Lor',
  //       'Udomsuk',
  //       'Victory Monument'
  //     ] },
  //   { location: 'Outer Bangkok',
  //     areas: [
  //       'Bang Kruai',
  //       'Bang Phli',
  //       'Bang Phun',
  //       'Bang Pu',
  //       'Bang Yai',
  //       'Dansamrong',
  //       'Lamlukka',
  //       'Navanakorn',
  //       'Pak Nam',
  //       'Rangsit',
  //       'Thepharak',
  //       'Thunyaburi'
  //     ] },
  //   { location: 'Other Provinces',
  //     areas: [
  //       'Ayutthaya',
  //       'Bang Saen',
  //       'Chachaengsai',
  //       'Chaing Mai',
  //       'Khon Kaen',
  //       'Lamphun',
  //       'Loei',
  //       'Nakhon Nayok',
  //       'Nakhon Pathom',
  //       'Nakhon Ratchasima',
  //       'Nakhon Sawan',
  //       'Pattaya',
  //       'Phuket',
  //       'Prajuabkirikun (Hua-Hin)',
  //       'Rayong',
  //       'Saraburi',
  //       'Suphan Buri'
  //     ] }
  // ],
  th: [
    {
      location: 'กรุงเทพมหานคร',
      areas: [
        'อโศก',
        'บางมด',
        'บางพลัด',
        'บางแค',
        'แจ้งวัฒนะ',
        'จรัญสนิทวงศ์',
        'ชิดลม',
        'ดอนเมือง',
        'แฮปปี้แลนด์',
        'หัวลำโพง',
        'หัวหมาก',
        'กัลปพฤก',
        'กรุงเทพกรีฑา',
        'ลาดพร้าว',
        'ลาซาน',
        'ลาดกระบัง',
        'ลาดปลาเคล้า',
        'มีนบุรี',
        'นานา',
        'หนองจอก',
        'งามวงศ์วาน',
        'หนองแขม',
        'อ่อนนุช',
        'ปัญญา อินทรา',
        'พัฒนาการ',
        'พหลโยธิน',
        'เพิ่มสิน',
        'พระโขนง',
        'ปิ่นเกล้า',
        'ประชาชื่น',
        'ประชาอุทิศ',
        'ปุณวิถี',
        'รามอินทรา',
        'พระราม 2',
        'พระราม 3',
        'พระราม 7',
        'รามคำแหง',
        'รัชดา',
        'ราชดำเนิน',
        'ร่มเกล้า',
        'สะพานสูง',
        'สาธุประดิษฐ์',
        'สยาม',
        'สีลม',
        'สายไหม',
        'สวนดุสิต',
        'สวนหลวง',
        'สุขสวัสดิ์',
        'ท่าพระ',
        'เทเวศ',
        'ธนบุรี',
        'ทองหล่อ',
        'อุดมสุข',
        'อุดมสุข'
      ]
    },
    {
      location: 'นอกกรุงเทพ',
      areas: [
        'บางกรวย',
        'บางพลี',
        'บางพูน',
        'บางปู',
        'บางใหญ่',
        'ด่านสำโรง',
        'ลำลูกกา',
        'นวนคร',
        'ปากน้ำ',
        'รังสิต',
        'เทพารักษ์',
        'ธัญบุรี'
      ]
    },
    {
      location: 'ต่างจังหวัด',
      areas: [
        'อยุธยา',
        'บางแสน',
        'ฉะเฉิงเทรา',
        'เชียงใหม่',
        'ขอนแก่น',
        'ลำพูน',
        'เลย',
        'นครนายก',
        'นครปฐม',
        'นครราชสีมา',
        'นครสวรรค์',
        'พัทยา',
        'ภูเก็ต',
        'ประจวบคีรีขันธ์',
        'ระยอง',
        'สระบุรี',
        'สุพรรณบุรี'
      ]
    }
  ],
  tw: [
    { location: '台北市',
      areas: [
        '士林區',
        '中山區',
        '大同區',
        '信義區',
        '松山區',
        '大安區',
        '內湖區',
        '文山區'
      ] },
    { location: '新北市',
      areas: [
        '板橋區',
        '中和區',
        '土城區',
        '三重區',
        '三峽區',
        '新莊區',
        '蘆洲區',
        '永和區',
        '林口區'
      ] },
    { location: '桃園市',
      areas: [
        '桃園區',
        '中壢區'
      ] },
    { location: '新竹市',
      areas: [
        '竹北市',
        '東區'
      ] },
    { location: '台中市',
      areas: [
        '西區',
        '東區',
        '北屯區',
        '北區',
        '南屯區',
        '大雅區',
        '豐原區'
      ] },
    { location: '彰化縣',
      areas: [
        '彰化市',
        '員林市'
      ] },
    { location: '嘉義市',
      areas: [
        '西區'
      ] },
    { location: '台南市',
      areas: [
        '中西區',
        '北區',
        '安南區'
      ] },
    { location: '高雄市',
      areas: [
        '前金區',
        '三民區',
        '苓雅區',
        '屏東市'
      ] }
  ],
  id: [
    { location: 'Jakarta',
      areas: [
        'Jakarta Barat',
        'Jakarta Pusat',
        'Jakarta Selatan',
        'Jakarta Utara'
      ] },
    { location: 'Bali',
      areas: [
        'Bali'
      ] },
    { location: 'Banten',
      areas: [
        'Tangerang'
      ] },
    { location: 'Jawa Barat',
      areas: [
        'Bekasi'
      ] },
    { location: 'Jawa Tengah',
      areas: [
        'Semarang'
      ] },
    { location: 'Jawa Timur',
      areas: [
        'Sidoarjo',
        ' Surabaya'
      ] },
    { location: 'Kalimantan Barat',
      areas: [
        'Ketapang'
      ] },
    { location: 'Kepulauan Riau',
      areas: [
        'Batam'
      ] },
    { location: 'Sumatera Barat',
      areas: [
        'Padang'
      ] },
    { location: 'Sumatera Selatan',
      areas: [
        'Lampung'
      ] },
    { location: 'Sumatera Utara',
      areas: [
        'Medan'
      ] }
  ]
};

export const jpHkClinicLocations = {
  jp: [
    {
      location: {
        en: 'Hokkaido',
        ja: '北海道'
      },
      areas: {
        en: [
          'Sapporo'
        ],
        ja: [
          '札幌市'
        ]
      }
    },
    {
      location: {
        en: 'Niigata',
        ja: '新潟県'
      },
      areas: {
        en: [
          'Niigata',
          'Shibata'
        ],
        ja: [
          '新潟市',
          '新発田市'
        ]
      }
    },
    {
      location: {
        en: 'Tokyo',
        ja: '東京都'
      },
      areas: {
        en: [
          'Shibuya',
          'Minato',
          'Setagaya'
        ],
        ja: [
          '渋谷区',
          '港区',
          '世田谷区'
        ]
      }
    },
    {
      location: {
        en: 'Kanagawa',
        ja: '神奈川県'
      },
      areas: {
        en: [
          'Yokohama'
        ],
        ja: [
          '横浜市'
        ]
      }
    },
    {
      location: {
        en: 'Aichi',
        ja: '愛知県'
      },
      areas: {
        en: [
          'Nagoya'
        ],
        ja: [
          '名古屋市'
        ]
      }
    },
    {
      location: {
        en: 'Kyoto',
        ja: '京都府'
      },
      areas: {
        en: [
          'Kyoto'
        ],
        ja: [
          '京都市'
        ]
      }
    },
    {
      location: {
        en: 'Osaka',
        ja: '大阪府'
      },
      areas: {
        en: [
          'Osaka'
        ],
        ja: [
          '大阪市'
        ]
      }
    },
    {
      location: {
        en: 'Hyogo',
        ja: '兵庫県'
      },
      areas: {
        en: [
          'Kobe'
        ],
        ja: [
          '神戸市'
        ]
      }
    },
    {
      location: {
        en: 'Nagasaki',
        ja: '長崎県'
      },
      areas: {
        en: [
          'Nagasaki'
        ],
        ja: [
          '長崎市'
        ]
      }
    },
    {
      location: {
        en: 'Kagoshima',
        ja: '鹿児島県'
      },
      areas: {
        en: [
          'Ichiki-Kushikino'
        ],
        ja: [
          'いちき串木野市'
        ]
      }
    }
  ],
  hk: [
    {
      location: {
        en: 'Hong Kong Island',
        zh: '香港島'
      },
      areas: {
        en: [
          'Central',
          'Fortress Hill',
          'Kennedy Town',
          'North Point',
          'Quarry Bay'
        ],
        zh: [
          '中環',
          '炮台山',
          '堅尼地城',
          '北角',
          '鰂魚涌'
        ]
      }
    },
    {
      location: {
        en: 'Kowloon',
        zh: '九龍'
      },
      areas: {
        en: [
          'Kwun Tong',
          'Mongkok',
          'Prince Edward',
          // 'Sham Shui Po',
          'Tsim Sha Shui'
        ],
        zh: [
          '觀塘',
          '旺角',
          '太子',
          // '深水埗',
          '尖沙咀'
        ]
      }
    },
    {
      location: {
        en: 'New Territories',
        zh: '新界'
      },
      areas: {
        en: [
          'Fanling',
          'Tiu Keng Leng',
          'Tsuen Wan',
          'Yuen Long'
        ],
        zh: [
          '粉嶺',
          '荃灣',
          '元朗',
          '調景嶺'
        ]
      }
    },
    {
      location: {
        en: 'Macau',
        zh: '澳門'
      },
      areas: {
        en: ['Macau'],
        zh: ['澳門']
      }
    }
  ]
};

export const animatedIBBannerEnabledMarkets = ['sg', 'hk', 'tw', 'vn'];

export const animatedIBBannerCta2Links = {
  'sg-en': 'https://www.youtube.com/embed/T513KRKazjo?rel=0',
  'hk-en': 'https://www.youtube.com/embed/T513KRKazjo?rel=0',
  'hk-zh': 'https://www.youtube.com/embed/ieD_UbkXuUw?rel=0',
  'tw-zh': 'https://www.youtube.com/embed/KmmBehok9R0?rel=0',
  'vn-vi': 'https://www.youtube.com/embed/lBWGj8JM4oM?rel=0'
};
