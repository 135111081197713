import { selector } from 'recoil';

import productListsState from '../atoms/productLists';

export const productListsData = selector({
  key: 'productListsData',
  get: ({ get }) => {
    const lists = get(productListsState);
    const newList = {};
    Object.keys(lists).forEach((key) => {
      newList[key] = lists[key].filter((ele) => !ele.configuration.isOutOfStock);
    });
    return newList;
  },
  set: ({ set, get }, newValue) => set(productListsState, { ...get(productListsState), ...newValue })
});

export const productListsFiltered = selector({
  key: 'productListsFiltered',
  get: ({ get }) => {
    const lists = get(productListsState);
    const newList = {};
    Object.keys(lists).forEach((key) => {
      newList[key] = [...new Map(lists[key].map((item) => [item.productKey, item])).values()];
    });
    return newList;
  }
});

export default productListsData;
